<template>
<div>
  <ContentProduct :mnn="this.mnn"/>
</div>
</template>
<script>
import ContentProduct from '../components/ui/ContentProduct'
export default {
  components: { ContentProduct },
  data: function () {
    return {
      mnn: this.$route.params.mnn
    }
  }
}
</script>
