<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_exclusive"></partnership-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="5" md="5" sm="11">
              <content-text :title="$t('message.partnershipSubmenu1')" :content="$t('message.partnershipText2')"></content-text>
              <div class="contentText">{{ $t('message.partnershipText3') }}</div>
              <div class="contentText">{{ $t('message.partnershipText4') }}</div>
              <h1 class="contentSubTitle">{{ $t('message.partnershipLogisticsSubHead1') }} </h1>
              <div class="contentText">{{ $t('message.aboutUsText3') }}</div>
              <div class="contentText">{{ $t('message.aboutUsText4') }}</div>
              <div class="contentText">{{ $t('message.aboutUsText5') }}</div>
          </v-col>
         <v-col cols="12" lg="7" md="7" sm="12">
                <div class="mt-4 text-center">
                  <a href="/img/ecology/сан.эпид.заключениеСМП.jpg" target="_blank">
                      <img src="/img/ecology/сан.эпид.заключениеСМП.jpg" style="max-width: 75%"/>
                  </a>
              </div>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu'
export default {
  components: { PartnershipSubmenu },
  data () {
    return {
      toggle_exclusive: 0
    }
  }
}
</script>
