<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <about-company-submenu :toggle_none="this.toggle_exclusive"/>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
        <v-col :key="value" cols="12" lg="12" md="12" sm="12" class="mt-0 pr-6 pr-sm-6 pr-md-0 pr-lg-0" v-for="value in news">
            <div v-if="lang=='ru'" >
              <h1 id="value.anchor" class="contentHeading" v-html="value.title"></h1>
              <ImgNews v-if="value.img!=''" :pathImg="value.img"/>
              <h5 class="newsData">{{getDate(value.created)}}</h5>
              <div class="pl-2 contentAnticorruption" v-html="value.text"></div>
            </div>
            <div v-else>
              <h1 id="value.anchor" class="contentHeading" v-html="value.en_title"></h1>
              <ImgNews v-if="value.img!=''" :pathImg="value.img"/>
              <h5 class="newsData">{{getDate(value.created)}}</h5>
              <div class="pl-2 contentAnticorruption" v-html="value.en_text"></div>
            </div>
        </v-col>
        </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import CardNews from '../components/ui/CardNews'
import ImgNews from '../components/ui/ImgNews'
export default {
  components: { ImgNews, CardNews, AboutCompanySubmenu },
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    news () {
      var _news = this.$store.state['news']['news']
      return _news.map(function (value, index) {
        var newValue = value
        newValue['anchor'] = index
        return newValue
      })
    }
  },
  methods: {
    getDate (value) {
      var date = new Date(value)
      var data = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      return data
    }
  },
  data () {
    this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': 10, 'offset': 0 })
    return {
      toggle_exclusive: 4,
      news1: '/img/news/example1.jpg',
      news2: '/img/news/example2.jpg',
      news3: '/img/news/example3.png',
      datePrev: '11.10.2019',
      dateNext: '15.10.2019',
      header: 'Компания “Фарм-Инновации” получила престижную фармацевтическую премию',
      text: '24 сентября в подмосковном городе Химки состоялось вручение ежегодной премии «Зеленый крест».\n' +
          '24 сентября в подмосковном городе Химки состоялось вручение ежегодной премии «Зеленый крест».'
    }
  }
}
</script>
