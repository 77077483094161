<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_none"></partnership-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <content-text :title="$t('message.partnershipHead')"></content-text>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="7" md="7" sm="12" >
              <div class="px-4 mt-4">
                  <a :href="$t('message.partnershipSchemeSRC')" target="_blank">
                      <img :src="$t('message.partnershipSchemeSRC')" width="100%"/>
                  </a>
              </div>
          </v-col>
          <v-col cols="12" lg="5" md="5" sm="12">
              <v-layout align-center justify-center row fill-height>
                  <v-row class="mx-7">
                      <v-col cols="1" class="mt-4">
                          <v-icon x-large>{{ mdiSvg }}</v-icon>
                      </v-col>
                      <v-col cols="11">
                          <div class="contentText font-weight-bold">{{ $t('message.partnershipText24') }}</div>
                          <div class="contentText ">{{ $t('message.partnershipText25') }}</div>
                      </v-col>
                  </v-row>
                  <v-row class="mx-7" >
                      <v-col cols="12">
                          <div class="py-4 px-10" style="background-color: rgba(30, 171, 214, .6);color: white; margin:0 auto;"> {{$t('message.partnershipText26')}}
                          <p style="font-weight: bold; margin-bottom: 0"> {{$t('message.partnershipBoldText1')}}</p></div>
                      </v-col>
                  </v-row>
              </v-layout>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu'
import { mdiCogs } from '@mdi/js'
export default {
  components: { PartnershipSubmenu },
  data () {
    return {
      toggle_none: undefined,
      mdiSvg: mdiCogs
    }
  }
}
</script>
