<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <about-company-submenu :toggle_none="this.toggle_exclusive"></about-company-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="6" md="6" sm="12">
              <content-text :title="$t('message.aboutUsSubmenu5')"></content-text>
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col :key="value" v-for="value in news" lg="4" md="4" sm="12">
            <card-news v-if="lang=='ru'"
                       :header="value.title"
                       :data="getDate(value.created)"
                       :text="value.anons"
                       :anchor="value.anchor"
            />
            <card-news v-else
                       :header="value.en_title"
                       :data="getDate(value.created)"
                       :text="value.en_anons"
                       :anchor="value.anchor"
            />
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import CardNews from '../components/ui/CardNews'
export default {
  components: { AboutCompanySubmenu, CardNews },
  methods: {
    getDate (value) {
      var date = new Date(value)
      var data = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      return data
    }
  },
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    news () { return this.$store.state['news']['news'] }
  },
  data () {
    this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': 10, 'offset': 0 })
    return {
      toggle_exclusive: 4
    }
  }
}
</script>
