<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <about-company-submenu :toggle_none="this.toggle_none"></about-company-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="5" md="5" sm="11">
              <content-text :title="$t('message.submenu1')" :content="$t('message.aboutUsText1')"></content-text>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
              <h1 class="contentSubHeading pl-lg-3 pl-md-3 pl-sm-0">{{ $t('message.contentNumericHead') }}</h1>
              <v-row class="no-indentation ml-0">
                  <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
                      <content-numeric :subtitle="$t('message.aboutUsSubHeading')"
                                       num="1"
                                       :unit="$t('message.contentUnit3')"
                                       symbol="+"
                                       :content="$t('message.contentNumeric3')"
                      />
                      <content-numeric :num="$t('message.contentNum5')"
                                       :content="$t('message.contentNumeric5')"
                      />
                      <content-numeric :num="$t('message.contentNum6')"
                                       :content="$t('message.contentNumeric6')"
                      />
                      <content-numeric num="~1"
                                       :unit="$t('message.contentUnit3')"
                                       :content="$t('message.contentNumeric7')"
                      />
                      <content-numeric num="~100"
                                       :unit="$t('message.contentUnit7')"
                                       :content="$t('message.contentNumeric10')"
                      />
                      <content-numeric num="6000"
                                       :unit="$t('message.contentUnit2')"
                                       :content="$t('message.contentNumeric12')"
                      />
                  </v-col>
                   <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
                      <content-numeric :subtitle="$t('message.aboutUsSubHeading')"
                                       num="95"
                                       symbol="+"
                                       :content="$t('message.contentNumeric4')"
                      />
                      <content-numeric num="50"
                                       :content="$t('message.contentNumeric8')"
                      />
                      <content-numeric num="70"
                                       :unit="$t('message.contentUnit8')"
                                       symbol="+"
                                       :content="$t('message.contentNumeric9')"
                      />
                      <content-numeric num="~15"
                                       :unit="$t('message.contentUnit7')"
                                       :content="$t('message.contentNumeric1')"
                      />
                      <content-numeric num="60"
                                       :unit="$t('message.contentUnit7')"
                                       :content="$t('message.contentNumeric11')"
                      />
                  </v-col>
              </v-row>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import Submenu from '../components/ui/Submenu'
import ContentText from '../components/ui/ContentText'
import ContentNumeric from '../components/ui/ContentNumeric'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { Submenu, ContentText, ContentNumeric, AboutCompanySubmenu },
  props: ['submenu'],
  data () {
    return {
      svgAllNewsBtnPath: mdiChevronRight,
      toggle_none: undefined
    }
  }
}
</script>
