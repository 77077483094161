<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <about-company-submenu :toggle_none="this.toggle_exclusive"></about-company-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pr-6 pr-sm-6 pr-md-0 pr-lg-0">
              <h1 class="contentHeading">{{ $t('message.aboutUsSubmenu4') }}</h1>
              <div class="pl-2 contentAnticorruption">{{ $t('message.aboutUsAntiCorruption1') }}</div>
              <div class="pl-2 contentAnticorruption">
                  <a :href="$t('message.aboutUsDocument3HREF')" target="_blank">{{ $t('message.aboutUsDocument3') }}</a>
              </div>
              <div class="pl-2 contentAnticorruption">
                  <a :href="$t('message.aboutUsDocument2HREF')" target="_blank">{{ $t('message.aboutUsDocument2') }}</a>
              </div>
              <div class="pl-2 contentAnticorruption">{{ $t('message.aboutUsAntiCorruption2') }}<a href="mailto:info@smp67.com">info@smp67.com</a>.
              </div>
              <div class="pl-2 contentAnticorruption">
                  <span>{{ $t('message.aboutUsAntiCorruption3') }}</span>
              </div>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" class="pr-6 pr-sm-6 pr-md-0 pr-lg-0 contentAnticorruptionMargin">
              <div class="pl-2 contentAnticorruption">
                  {{ $t('message.aboutUsAntiCorruption4') }}
              </div>
              <div class="pl-2 contentAnticorruption">
                  <ul>
                      <li class="mb-4">
                          <span>{{ $t('message.aboutUsAntiCorruption5') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.aboutUsAntiCorruption6') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.aboutUsAntiCorruption7') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.aboutUsAntiCorruption8') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.aboutUsAntiCorruption9') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.aboutUsAntiCorruption10') }}</span>
                      </li>
                  </ul>
              </div>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
export default {
  components: { AboutCompanySubmenu },
  data () {
    return {
      toggle_exclusive: 3
    }
  }
}
</script>
