<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <about-company-submenu :toggle_none="this.toggle_exclusive"></about-company-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pr-6 pr-sm-6 pr-md-0 pr-lg-0">
              <h1 class="contentHeading">{{ $t('message.aboutUsSubmenu31') }}</h1>
              <div class="contentTypesOfActivities contentTypesOfActivitiesComment">
                  {{ $t('message.aboutUsTypesOfActivitiesSveden') }}
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                          <v-icon style="width:20px; height:20px; margin-top: -3px"  v-on="on">{{ svgHelpPath }}</v-icon>
                      </template>
                      <span>{{ $t('message.aboutUsTypesOfActivitiesEGRUL') }}</span>
                  </v-tooltip>
              </div>
              <h1 class="contentSubTitle">{{ $t('message.aboutUsTypesOfActivitiesOsnTitle') }}</h1>
              <div class="contentTypesOfActivities">{{ $t('message.aboutUsTypesOfActivitiesOsn') }}</div>
              <h1 class="contentSubTitle">{{ $t('message.aboutUsTypesOfActivitiesDop') }}</h1>
              <div class="contentTypesOfActivities">{{ $t('message.aboutUsTypesOfActivitiesDop1') }}</div>
              <div class="contentTypesOfActivities">{{ $t('message.aboutUsTypesOfActivitiesDop2') }}</div>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="10" offset-lg="1" offset-md="1" offset-sm="0" class="mx-auto mx-sm-0 marginLicense text-center">
              <license-slider></license-slider>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import ContentDocuments from '../components/ui/ContentDocuments'
import LicenseSlider from '../components/ui/LicenseSlider'
import { mdiHelpCircleOutline } from '@mdi/js'
export default {
  components: { AboutCompanySubmenu, ContentDocuments, LicenseSlider },
  data () {
    return {
      toggle_exclusive: 2,
      svgHelpPath: mdiHelpCircleOutline
    }
  }
}
</script>
