<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="12" md="12" sm="12">
              <content-text :title="$t('message.careerSubHead')"/>
              <div class="contentText mt-0">{{ $t('message.careerText1') }}
              </div>
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="5" md="5" sm="11">
              <h1 class="contentSubTitle mt-0">{{ $t('message.careerSubTitle1') }} </h1>
              <div class="contentTypesOfActivities">
                  <v-icon class="my-lg-2 my-md-2 my-sm-2 mr-lg-2 mr-md-2 mr-sm-2">{{ svgPath1 }}</v-icon>
                  {{ $t('message.careerText2') }}
              </div>
              <div class="contentTypesOfActivities">
                  <v-icon class="mt-lg-1 mt-md-1 mb-lg-1 mb-md-1 mb-sm-1 mr-lg-2 mr-md-2 mr-sm-2">{{ svgPath2 }}</v-icon>
                  {{ $t('message.careerText3') }}
              </div>
              <div class="contentTypesOfActivities">
                  <v-icon class="my-lg-2 my-md-2 my-sm-2 mr-lg-2 mr-md-2 mr-sm-2">{{ svgPath3 }}</v-icon>
                  {{ $t('message.careerText4') }}
              </div>
              <h1 class="contentSubTitle">{{ $t('message.careerSubTitle2') }} </h1>
              <div class="contentText">
                  {{ $t('message.careerText5') }} <a href="tel:+ 7(910)728-84-08">+ 7(910)728-84-08</a>
              </div>
              <div class="contentText">
                  {{ $t('message.careerText6') }}
                  <a href="https://smolensk.hh.ru/employer" target="_blank">HeadHunter</a>
              </div>
              <div class="contentText">
                   {{ $t('message.careerText7') }}
              </div>
          </v-col>
          <v-col cols="12" lg="4" md="5" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
              <work-form/>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import WorkForm from '../components/ui/WorkForm'
import { mdiFinance, mdiCash, mdiBrain } from '@mdi/js'
export default {
  components: { WorkForm },
  data () {
    return {
      svgPath1: mdiFinance,
      svgPath2: mdiCash,
      svgPath3: mdiBrain
    }
  }
}
</script>
