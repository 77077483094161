<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="4" md="4" sm="12">
              <content-text :title="$t('message.submenu4')"></content-text>
              <h1 class="contentSubTitle mt-0">{{ $t('message.contactSubTitle1') }} </h1>
              <div class="contentText">
                  {{ $t('message.contactText1') }} <br>
                  {{ $t('message.contactPhone') }}: + 7(910)728-84-08
              </div>
              <h1 class="contentSubTitle">{{ $t('message.contactSubTitle2') }} </h1>
              <div class="contentText">
                  {{ $t('message.contactText2') }}<br>
<!--                  {{ $t('message.contactPhone') }}: + 7(910)728-84-08-->
              </div>
              <div class="contentText">
                  {{ $t('message.contactText3') }} <br>
                  {{ $t('message.contactText4') }}: <a href="mailto:info@smp67.com" target="_blank">info@smp67.com</a><br>
                  {{ $t('message.contactText5') }} <a @click.stop="dialog = true">{{ $t('message.contactText6') }}</a>
                  <v-row justify="center">
                      <v-dialog v-model="dialog"
                                max-width="568"
                      >
                          <v-card class="pa-8">
                              <v-card-title class="headline">{{ $t('message.feedBackForm') }}</v-card-title>
                              <v-card-text>
                                  <feedback/>
                              </v-card-text>
                          </v-card>
                      </v-dialog>
                  </v-row>
              </div>
          </v-col>
          <v-col cols="12" lg="8" md="8" sm="12">
              <div class="partnershipAdvantage pa-0">
                  <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=43149250933"></iframe>
              </div>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import Feedback from '../components/ui/FeedbackForm'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { Feedback },
  data () {
    return {
      dialog: false,
      svgAllNewsBtnPath: mdiChevronRight
    }
  }
}
</script>
