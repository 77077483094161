<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_exclusive"/>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="12" md="12" sm="12">
              <content-text :title="$t('message.partnershipSubmenu3')"/>
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/Kirso.png" href-partner="https://corp.smolinvest.com/"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/adinath.png" href-partner="http://www.adinath.co.in/"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/sterile.png" href-partner="http://sterileindia.com/"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/SPHFU.png" href-partner="https://spb.ucheba.ru/uz/6162"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/hhl.png" href-partner="https://www.humblehealthcaare.com/"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/PMV.png" href-partner="https://www.pmvlifescience.com/"/>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/admin.png" href-partner="https://admin-smolensk.ru/"/>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import CardLogo from '../components/ui/CardLogo'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { CardLogo },
  data () {
    return {
      svgAllNewsBtnPath: mdiChevronRight,
      toggle_exclusive: 1
    }
  }
}
</script>
