<template>
  <v-container fluid class="mb-12">
      <v-row class="no-indentation mt-6 mobileDisplay">
          <v-col lg="0" md="0" sm="12" class="cardBlock">
              <cards-with-slogan-mobile class="borderCardMobile"/>
          </v-col>
      </v-row>
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10">
          <v-spacer class="cardSpacer"/>
          <v-col lg="3" md="3" sm="12" class="cardBlock">
              <cards-with-slogan/>
          </v-col>
          <v-col lg="2" md="2" sm="3" class="cardBlock">
              <cards-with-numbers :text="$t('message.contentNumeric1')"
                                  big-num="15"
                                  symbol=" +"
                                  :svgPath="svgNum1"
              />
          </v-col>
          <v-col lg="2" md="2" sm="3" class="cardBlock">
              <cards-with-numbers :text="$t('message.contentNumeric7')"
                                  big-num="1"
                                  :unit="$t('message.contentUnit3')"
                                  symbol=" +"
                                  :svgPath="svgNum2"
              />
          </v-col>
          <v-col lg="2" md="2" sm="3" class="cardBlock">
              <cards-with-numbers :text="$t('message.contentNumeric3')"
                                  big-num="1"
                                  :unit="$t('message.contentUnit3')"
                                  symbol=" +"
                                  :svgPath="svgNum3"
              />
          </v-col>
          <v-col lg="2" md="2" sm="3" class="cardBlock">
              <cards-with-numbers :text="$t('message.contentNumeric8')"
                                  big-num="50"
                                  :svgPath="svgNum4"
              />
          </v-col>
           <v-spacer class="cardSpacer"/>
      </v-row>
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10">
          <v-spacer class="cardSpacer"/>
          <v-col lg="11" md="11" sm="11" class="cardBlock pl-lg-0 pl-md-2 pl-sm-0 cardItemNews">
              <v-btn text @click="pushAllNews()">{{ $t('message.allNews') }} <v-icon>{{ svgAllNewsBtnPath }}</v-icon></v-btn>
          </v-col>
          <v-spacer class="cardSpacer"/>
      </v-row>
      <v-row  class="no-indentation mt-lg-8 mt-md-8 mt-sm-6">
          <v-spacer class="cardSpacer"/>
          <v-col :key="value" v-for="value in news" lg="4" md="4" sm="12" class="cardBlock newsBlock px-lg-3 px-md-1">
              <v-spacer class="cardSpacer" :style="value.spacer"/>
              <cards-news :style="value.color"
                          v-if="lang=='ru'"
                          :header="value.title"
                          :data="getDate(value.created)"
                          :text="value.anons"
                          :anchor="value.anchor"
              />
              <cards-news :style="value.color"
                          v-else
                          :header="value.en_title"
                          :data="getDate(value.created)"
                          :text="value.en_anons"
                          :anchor="value.anchor"
              />
          </v-col>
          <v-spacer class="cardSpacer"></v-spacer>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import CardsWithSlogan from '../components/ui/CardsWithSlogan'
import CardsWithSloganMobile from '../components/ui/CardsWithSloganMobile'
import CardsWithNumbers from '../components/ui/CardsWithNumbers'
import CardsNews from '../components/ui/CardsNews'
import { mdiChevronRight, mdiBasketOutline, mdiAccountMultipleOutline, mdiStore, mdiCurrencyRub } from '@mdi/js'
export default {
  props: [ 'svgPath' ],
  components: { CardsWithSlogan, CardsWithSloganMobile, CardsWithNumbers, CardsNews },
  methods: {
    getDate (value) {
      var date = new Date(value)
      var data = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      return data
    },
    pushAllNews () {
      this.$router.push('/news')
    }
  },
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    news () {
      var _news = this.$store.state['news']['news']
      var color = ['background-color: #32ABD1!important;', 'background-color: #F07C53!important;', 'background-color: #FFA901!important;']
      var spacer = ['', 'flex-grow: 0.55!important;', 'flex-grow: 0.55!important;']
      return _news.map(function (value, index) {
        var newValue = value
        newValue['color'] = color[index]
        newValue['spacer'] = spacer[index]
        newValue['anchor'] = index
        return newValue
      })
    }
  },
  data () {
    this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': 3, 'offset': 0 })
    return {
      svgAllNewsBtnPath: mdiChevronRight,
      svgNum1: mdiBasketOutline,
      svgNum2: mdiStore,
      svgNum3: mdiCurrencyRub,
      svgNum4: mdiAccountMultipleOutline
    }
  }
}
</script>
