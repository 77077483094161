<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-12 px-md-0">
      <partnership-submenu :toggle_none="this.toggle_exclusive"/>
    </v-row>
    <v-row class="no-indentation px-4 px-sm-4 px-md-4 px-lg-12">
      <v-col cols="12" lg="12" md="12" sm="12" class="px-0 py-0">
        <content-text :title="$t('message.partnershipSubmenu4')" :content="$t('message.partnershipEffectContent1')"/>
      </v-col>
    </v-row>
    <v-row justify="center" class="mx-auto" >
        <content-textsvg :img_name="'m_people'" :number="$t('message.partnershipEffectNumber1')" :text="$t('message.partnershipEffectTextWithNumber1')"/>
        <content-textsvg :img_name="'wallet'" :number="$t('message.partnershipEffectNumber2')" :text="$t('message.partnershipEffectTextWithNumber2')"/>
        <content-textsvg :img_name="'home'" :number="$t('message.partnershipEffectNumber3')" :text="$t('message.partnershipEffectTextWithNumber3')"/>
        <content-textsvg :img_name="'income'" :number="$t('message.partnershipEffectNumber4')" :text="$t('message.partnershipEffectTextWithNumber4')"/>
        <content-textsvg :img_name="'NDS'" :number="$t('message.partnershipEffectNumber5')" :text="$t('message.partnershipEffectTextWithNumber5')"/>
        <content-textsvg :img_name="'calculator'" :number="$t('message.partnershipEffectNumber6')" :text="$t('message.partnershipEffectTextWithNumber6')"/>
    </v-row>
    <v-row class="no-indentation" >
    <v-col cols="12" sm="12" md="6" lg="6" class="px-8 px-sm-8 pl-md-8 pl-lg-14 pr-12">
      <div> {{$t('message.partnershipEffectText1')}} </div>
    </v-col>
    <v-col cols="12" sm="12" md="6" class="px-8 px-sm-8 pr-md-8  pr-lg-12 ">
      <div> {{$t('message.partnershipEffectText2')}} </div>
    </v-col>
    </v-row>
    <v-row class="mb-8 mt-8 mx-auto"><v-img src="../../public/img/effect/blue_line.png"/></v-row>
    <v-row class="no-indentation ">
      <v-col cols="12" sm="12" md="6" lg="6" class="px-8 px-sm-8 mt-8 pl-md-8 pl-lg-15 " >
        <div class="py-4 px-4" style="border-left:1px solid rgba(30, 171, 214, 1);"> {{$t('message.partnershipEffectTextLeftBorder1')}} </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" class="px-8 px-sm-8 mt-8 pr-md-8 pr-12" >
        <div class="py-4 px-10" style="background-color: rgba(30, 171, 214, .6);color: white"> {{$t('message.partnershipEffectTextBlack1beforespan')}}
          <span style="font-weight: bold"> {{$t('message.partnershipEffectTextBlack1span')}}</span>
          {{$t('message.partnershipEffectTextBlack1afterspan')}}</div>
      </v-col>
    </v-row>
    <v-row class="no-indentation ">
      <v-col cols="12" sm="12" md="6" lg="6" class="px-8 px-sm-8 mt-8 pl-md-8 pl-lg-15 pr-12" >
        <div style="border-left:1px solid rgba(30, 171, 214, 1);" class="pl-4 pt-4">
          {{$t('message.partnershipEffectTextLeftBorder2beforespan')}}
          <span style="color: rgba(30, 171, 214, 1);">{{$t('message.partnershipEffectTextLeftBorder2span')}}</span>
          {{$t('message.partnershipEffectTextLeftBorder2afterspan')}} </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" class="px-8 px-sm-8 pr-md-8 pr-lg-12 pl-md-12 mt-8">
        <v-img src="../../public/img/effect/factory.png"
        height="176" width="576"/>
      </v-col>
    </v-row>
     <v-row class="mb-8 mt-8 mx-auto"><v-img src="../../public/img/effect/blue_line.png"/></v-row>
    <v-row class="px-4 no-indentation">
      <v-col  v-for="product in products" :key="product.mnn" cols="12" sm="12" md="6" lg="6" class="px-7 px-sm-7 px-md-7 px-lg-14 py-12">
        <ContentEffect v-if="lang=='ru'" :img="product.img" :name="product.mnn" :price="product.price"></ContentEffect>
        <ContentEffect v-if="lang=='en-US'" :img="product.img" :name="product.en_mnn" :price="product.price"></ContentEffect>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../plugins/i18n.js'
import ContentTextsvg from '../components/ui/ContentTextsvg'
import ContentText from '../components/ui/ContentText'
import СontentEffect from '../components/ui/ContentEffect'
import {
  mdiCogs,
  mdiHomeOutline,
  mdiCurrencyUsd
} from '@mdi/js'
export default{
  components: { ContentText, ContentTextsvg, СontentEffect },
  computed: {
    lang () { return this.$i18n.locale },
    products () { return this.$store.getters['products/products'] }
  },

  name: 'EffectivenessProject',
  data () {
    this.$store.dispatch('products/GET_PRODUCTS', { 'filters': [], 'limit': 10, 'offset': 0 })
    return {
      svgImage1: mdiHomeOutline,
      svgImage4: mdiCurrencyUsd,
      svgImage7: mdiCogs,
      toggle_exclusive: 2
    }
  }
}
</script>
