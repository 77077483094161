<template>
      <v-container fluid class="no-indentation mb-12">
          <v-row class="no-indentation px-lg-12 px-md-0">
              <v-col cols="12" lg="6" md="6" sm="12">
                  <h1 class="contentSubTitle">{{$t('message.projectProductsSubTitle')}}</h1>
              </v-col>
          </v-row>
          <v-row class="no-indentation px-lg-8">
              <v-col v-for="value in products_in_project" :key=value.mnn lg="4" md="6" sm="10" class="mx-sm-auto mx-md-0">
                <CardProduct :img="value.img" :name="value.mnn" :en_name="value.en_mnn" :anchor="value.anchor"/>
              </v-col>
          </v-row>
      </v-container>
</template>
<script>
import CardProduct from '../components/ui/CardProduct'
import ButtonExpand from '../components/ui/ButtonExpand'
export default {
  components: { CardProduct, ButtonExpand },
  data () {
    this.$store.dispatch('products/GET_PRODUCTS', { 'filters': [], 'limit': 10, 'offset': 0 })
    return {
      toggle_exclusive: 4
    }
  },
  computed: {
    lang () { return this.$i18n.locale },
    products_in_project () { return this.$store.state['products']['products'].filter(product => product.type === 'products_in_project') }
  }
}
</script>
